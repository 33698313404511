import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
} from '@auth0/auth0-spa-js';
import { navigate } from 'gatsby';
import { window } from 'browser-monads';

type AppState = {
  path: string;
};

const { location } = window;

const DEFAULT_REDIRECT_CALLBACK = (appState: AppState) =>
  navigate(appState?.path || '/', { replace: true });

type Props = {
  client_id: string;
  domain: string;
  redirect_uri: string;
  children: ReactNode;
  onRedirectCallback?: Function;
};

export const AuthContext = createContext({} as Auth0ClientOptions);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...options
}: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState();
  const [authClient, setAuthClient] = useState<Auth0Client>({} as Auth0Client);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      const authFromHook = await createAuth0Client(options);
      setAuthClient(authFromHook);

      if (
        location.search.includes('code=') &&
        location.search.includes('state=')
      ) {
        const { appState } = await authFromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await authFromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await authFromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await authClient.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await authClient.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await authClient.handleRedirectCallback();
    const user = await authClient.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        ...options,
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => authClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => authClient.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => authClient.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => authClient.getTokenWithPopup(...p),
        logout: (...p: any) => authClient.logout(...p),
        login: (...p: any) =>
          authClient.loginWithRedirect({
            appState: {
              path: location.pathname,
            },
            ...p,
          }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
