export type Settings = {
  environment?: string;
  api: {
    places: {
      uri?: string;
      token?: string;
      projectId?: string;
      dataset?: string;
      useCdn?: boolean;
      watchMode?: string;
      overlayDrafts?: string;
    };
    follows: {
      uri?: string;
      token?: string;
    };
  };
};

export const settings: Settings = {
  environment: process.env.NODE_ENV,
  api: {
    places: {
      uri: process.env.GATSBY_SANITY_PLACES_API_URI,
      token: process.env.GATSBY_SANITY_PLACES_AUTH_TOKEN,
      projectId: process.env.GATSBY_SANITY_PLACES_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_PLACES_DATASET,
      useCdn:
        process.env.GATSBY_SANITY_PLACES_USE_CDN?.toLowerCase() === 'true',
      watchMode: process.env.GATSBY_SANITY_PLACES_WATCH_MODE,
      overlayDrafts: process.env.GATSBY_SANITY_PLACES_OVERLAY_DRAFTS,
    },
    follows: {
      uri: 'http://localhost:8080/graphql',
      token: `123456789`,
    },
  },
};

export default settings;
