import LogRocket from 'logrocket';
import { window } from 'browser-monads';

import { settings } from './settings';

const { location } = window;

export const onClientEntry = () => {
  console.log('========== On Client Entry ==========');
  console.log(`Environment: ${settings.environment}`);

  if (settings.environment === 'development') {
    LogRocket.init('6ut0wg/reactable-development');
    console.log('LogRocket: Reactable Development');
  }

  if (settings.environment === 'production') {
    // LogRocket.init();
    console.log('LogRocket: Reactable Production');
  }

  LogRocket.identify(location.href);
};

export default onClientEntry;
