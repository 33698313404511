import { combineReducers } from 'redux';
import { unionBy } from 'lodash';

import { IFollow } from '@types';
import {
  SET_MENU,
  SET_SEARCH,
  SET_FOLLOWS,
  FOLLOW,
  UNFOLLOW,
  SET_LAYOUT,
} from './actions';

type Action = {
  type: string;
  payload?: any;
  meta?: any;
  error?: boolean;
};

const filter = (
  state: object = {
    search: '',
  },
  action: Action
) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        search: action.payload.toLowerCase(),
      };
    default:
      return state;
  }
};

const active = (
  state: any = {
    menu: {},
  },
  action: Action
) => {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    default:
      return state;
  }
};

const follows = (state: IFollow[] = [], action: Action) => {
  switch (action.type) {
    case SET_FOLLOWS:
      return unionBy([...state], action.payload, 'externalId');
    case FOLLOW:
      return unionBy(
        [...state],
        [action.payload],
        'externalId'
      ).map((follow: IFollow) =>
        follow.externalId === action.payload.externalId
          ? action.payload
          : follow
      );
    case UNFOLLOW:
      return unionBy(
        [...state],
        [action.payload],
        'externalId'
      ).map((follow: IFollow) =>
        follow.externalId === action.payload.externalId
          ? action.payload
          : follow
      );
    default:
      return state;
  }
};

const display = (
  state: any = {
    layout: 'grid',
  },
  action: Action
) => {
  switch (action.type) {
    case SET_LAYOUT:
      return {
        layout: action.payload,
      };
    default:
      return state;
  }
};

export const reducers = combineReducers({
  active,
  follows,
  filter,
  display,
});

export default reducers;
