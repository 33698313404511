import tracking from '@services/tracking';

import ApolloClient from 'apollo-boost';

import settings from '@app/settings';
import { queryFollows, mutateFollow } from '@queries/reactable/follows';
import { IMenuItem, IPlace } from '@types';
import { Dispatch } from 'redux';

export const SET_SEARCH = 'SET_SEARCH';
export const SET_MENU = 'SET_MENU';
export const SET_FAVORITES = 'SET_FAVORITES';
export const SET_FOLLOWS = 'SET_FOLLOWS';
export const FOLLOW = 'FOLLOW';
export const UNFOLLOW = 'UNFOLLOW';
export const SET_LAYOUT = 'SET_LAYOUT';

/*======================================================================
  Layout
======================================================================*/
export const setLayout = (layout: string) => (dispatch: Function) =>
  dispatch({
    type: SET_LAYOUT,
    payload: layout,
  });

/*======================================================================
  Search
======================================================================*/
export const setSearch = (query: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_SEARCH,
    payload: query,
  });

/*======================================================================
  Menus
======================================================================*/
export function setActiveMenu(menu: any) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_MENU,
      payload: menu,
    });
  };
}

/*======================================================================
  Follows
======================================================================*/
const client = new ApolloClient({
  uri: settings.api.follows.uri,
  headers: {
    authorization: `Bearer ${settings.api?.follows?.token}`,
  },
});

export function fetchFollowsByAccount(accountId: string) {
  return async (dispatch: Dispatch) => {
    const result = await client.query({
      query: queryFollows,
      variables: {
        accountId: accountId,
      },
      fetchPolicy: 'no-cache',
    });
    const { items } = result.data?.follows;

    dispatch({
      type: SET_FOLLOWS,
      payload: items,
    });

    return items;
  };
}

export const follow = (item: IMenuItem | IPlace) => {
  return async (dispatch: Dispatch) => {
    const result = await client.mutate({
      mutation: mutateFollow,
      variables: {
        externalId: item._id,
        followed: true,
      },
      fetchPolicy: 'no-cache',
    });

    const { follow } = result.data;
    console.log('Followed: ', follow);

    dispatch({
      type: FOLLOW,
      payload: follow,
    });

    return follow;
  };
};

export const unfollow = (item: IMenuItem | IPlace) => {
  return async (dispatch: Dispatch) => {
    const result = await client.mutate({
      mutation: mutateFollow,
      variables: {
        externalId: item._id,
        followed: false,
      },
      fetchPolicy: 'no-cache',
    });

    const { follow } = result.data;
    console.log('Unfollowed: ', follow);

    dispatch({
      type: UNFOLLOW,
      payload: follow,
    });

    return follow;
  };
};

/*======================================================================
  Tracking
======================================================================*/
export const addView = () => () => tracking.addView();
