import localforage from 'localforage';
import { IMenuItem } from '@types';

type Subscribe = {
  show: boolean;
};

class Tracking {
  private views: number = 0;
  private subscribe: Subscribe = {
    show: true,
  };
  private favorites: IMenuItem[] = [];

  // Public
  public addView = () =>
    this.getViews().then(() => {
      this.views = this.views + 1;
      return this.saveViews();
    });

  public getViews = () =>
    localforage.getItem('views').then((views: any) => {
      if (views) this.views = views;
      return this.saveViews();
    });

  public setSubscribe = (show: boolean) => {
    this.subscribe.show = show;
    return this.saveSubscribe();
  };

  public getSubscribe = () =>
    localforage.getItem('subscribe').then((subscribe: any) => {
      if (subscribe) this.subscribe = subscribe;
      return this.saveSubscribe();
    });

  public getFavorites = () =>
    localforage.getItem('favorites').then((favorites: any) => {
      if (favorites) this.favorites = favorites;
      return this.saveFavorites();
    });

  public setFavorites = (favorites: IMenuItem[]) => {
    this.favorites = favorites;
    return this.saveFavorites();
  };

  // Private
  private saveViews() {
    return localforage.setItem('views', this.views);
  }

  private saveSubscribe() {
    return localforage.setItem('subscribe', this.subscribe);
  }

  private saveFavorites() {
    return localforage.setItem('favorites', this.favorites);
  }
}

export default new Tracking();
