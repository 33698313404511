import gql from 'graphql-tag';

const fragments = {
  pageInfo: gql`
    fragment PageInfoFields on PageInfo {
      total
      size
    }
  `,
  followFields: gql`
    fragment FollowFields on Follow {
      _id
      userId
      accountId
      externalId
      externalType
      revision
      followed
      createdAt
      modifiedAt
    }
  `,
  followsFields: gql`
    fragment FollowsFields on FollowPage {
      items {
        ...FollowFields
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  `,
};

export const queryFollows = gql`
  query Follows($accountId: ID!) {
    follows: follows(accountId: $accountId) {
      ...FollowsFields
    }
  }

  ${fragments.pageInfo}
  ${fragments.followFields}
  ${fragments.followsFields}
`;

export const mutateFollow = gql`
  mutation Follow($externalId: ID!, $followed: Boolean!) {
    follow(externalId: $externalId, followed: $followed) {
      ...FollowFields
    }
  }

  ${fragments.followFields}
`;
