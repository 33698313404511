import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCommentAltLines,
  faThumbtack,
  faInfo,
  faHeart,
  faWindowClose,
  faWineGlassAlt,
  faShare,
} from '@fortawesome/pro-light-svg-icons';

import {
  faUtensilFork as faUtensilForkReg,
  faGlass as faGlassReg,
  faHeart as faHeartReg,
  faWineGlass as faWineGlassReg,
  faGlassMartini as faGlassMartiniReg,
  faWindowClose as faWindowCloseReg,
  faTh as faThReg,
  faList as faListReg,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCommentAltLines as faCommentAltLinesSolid,
  faThumbtack as faThumbtackSolid,
  faHeart as faHeartSolid,
  faInfo as faInfoSolid,
  faShare as faShareSolid,
  faWineGlassAlt as faWineGlassAltSolid,
  faChevronRight as faChevronRightSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFacebookSquare,
  faTwitterSquare,
  faPinterestSquare,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  // Light
  faHeart,
  faInfo,
  faCommentAltLines,
  faThumbtack,
  faShare,
  faWindowClose,
  faWineGlassAlt,
  // Regular
  faUtensilForkReg,
  faGlassReg,
  faHeartReg,
  faWineGlassReg,
  faGlassMartiniReg,
  faWindowCloseReg,
  faThReg,
  faListReg,
  // Solid
  faInfoSolid,
  faCommentAltLinesSolid,
  faHeartSolid,
  faThumbtackSolid,
  faShareSolid,
  faWineGlassAltSolid,
  faChevronRightSolid,
  // Brands
  faFacebookSquare,
  faTwitterSquare,
  faPinterestSquare
);
