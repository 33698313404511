import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { window } from 'browser-monads';

import createStore from '../providers/create-store';
import settings from './settings';
import AuthProvider from '../providers/auth-provider';
import { AUTH_CONFIG } from '../providers/auth-config';
import './fontawesome';

type Props = {
  element: ReactNode;
};

const { location } = window;

const client = new ApolloClient({
  uri: settings.api.places.uri,
  headers: {
    authorization: `Bearer ${settings.api.places.token}`,
  },
});

export const wrapRootElement = ({ element }: Props) => {
  console.log('========== Wrap Root Element ==========');

  // Redux Store
  const store = createStore();

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AuthProvider
          domain={AUTH_CONFIG.domain}
          client_id={AUTH_CONFIG.clientId}
          redirect_uri={`${location.origin}/${AUTH_CONFIG.loginPath}`}
        >
          {element}
        </AuthProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default wrapRootElement;
